import React, { PureComponent, createRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { getSystemParameters } from '../../../core/functions/systemInfo';
import { SystemParametersEnum } from '../../../core/enums/SystemParametersEnum';
import "./WagxEditor.css"

class WagxEditor extends PureComponent {
    editorRef = createRef(null);

    handleEditorChange = (content, editor) => {
        if (this.timeout) {
            clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(() => {
            this.props.onChange(null, this.props.field, content, null, this.props.formId);
        }, 1000);
    }
    render() {
        return <Editor
            apiKey={getSystemParameters(SystemParametersEnum.TINYMCE_API_KEY)}
            init={{
                height: 250,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                    'bullist numlist'
                ],
                toolbar: 'bold italic underline | fontselect fontsizeselect | bullist numlist alignleft aligncenter alignright alignjustify undo redo | table'
            }
            }
            onEditorChange={(content, editor) => { this.handleEditorChange(content, editor) }} />
    }
}


export default WagxEditor;