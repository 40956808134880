import _ from 'lodash';
import React from 'react';
import GenericInput from '../components/GenericInput/GenericInput';
import axios from '../axios-wagx-web';
import { store } from '../../store-config';
import * as actions from '../store/actions';
import { Label } from 'office-ui-fabric-react';
import { getLocalizedProperty } from '../LocaleUtils';
import { ErrorLabel } from '../components/ErrorLabel/ErrorLabel';
import { getMessageSeverityEnumByKey, MessageSeverityEnum } from '../enums/MessageSeverityEnum';
import intl from 'react-intl-universal';
import {
  parseString as StringUtils_parseString,
  parseStringFromApplyFilters as StringUtils_parseStringFromApplyFilters,
  parseConditionString as StringUtils_parseConditionString,
  parseConditionStringFromApplyFilters as StringUtils_parseConditionStringFromApplyFilters,
  textContains as StringUtils_textContains,
  findIconName as StringUtils_findIconName,
  isEmpty as StringUtils_isEmpty,
  isNotEmpty as StringUtils_isNotEmpty,
  isNullOrEmpty as StringUtils_isNullOrEmpty,
  isNotNullOrEmpty as StringUtils_isNotNullOrEmpty
} from './StringUtils';
import * as formatters from '../functions/formatters';
import { getInternationalizationInfo as SystemInfo_getInternationalizationInfo } from './systemInfo';

export const appCloseTab = () => {
  window.dispatchEvent(new Event('custom.appclosetab'));
}

export const getInternationalizationInfo = () => {
  return SystemInfo_getInternationalizationInfo();
}

export const distinctFilter = (keys) => {
  return Array.isArray(keys) !== true || keys.length === 0
    ? o => true
    : (s => o =>
      (k => !s.has(k) && s.add(k))
        (keys.map(k => o[k]).join('|'))
    )(new Set());
}

export const validateInputValue = (value, type) => {
  let isValid = true;

  if ((value && value !== "") && type) {
    switch (type) {
      case "int":
      case "number":
        isValid = !isNaN(value);
        break;
      default:
        isValid = true;
        break;
    }
  }

  return isValid;
}

export const manageError = (errorMessage, source, errorObject, formCaption, formText) => {
  console.error(errorMessage, source, errorObject, formCaption, formText);
  try {
    if (errorObject == null || errorObject.response == null || errorObject.response.status !== 404) {
      let data = {};
      data.errorMessage = errorMessage + ((errorObject != null && errorObject.hasOwnProperty('toString')) ? (" -> " + errorObject.toString()) : '');
      data.source = JSON.stringify(source);

      data.info = JSON.stringify(errorObject);
      axios.post("/clientError", data).then(response => {
        store.dispatch(actions.setHasErrorAndIdClientError(true, response.data.value, formCaption, formText));
      }).catch(() => {
        store.dispatch(actions.setHasErrorAndIdClientError(true, null, formCaption, formText));
      });
    } else if (errorObject != null && errorObject.response != null && errorObject.response.status === 404) {
      /*
        Gli attuali componenti wagx ritornano lo stato 404 quando una ricerca non ha prodotto risultati.
        Sarà da modificare questo comportamento così che lo stato 404 verrà ritornato solo nel caso in
        cui viene invocato un url mancante.
      */
      // let fCaption = formCaption != null ? formCaption : "Errore";
      // let fText = formText != null ? formText : "Risorsa non trovata";        
      // store.dispatch(actions.setHasErrorAndIdClientError(true, null, fCaption, fText));
    }
  } catch (e) {
    console.error("Fail to manage error", e, errorObject);
  }
}

export const getValueFromInput = (event, customInputName, selectedKey) => {
  if (selectedKey !== null && selectedKey !== undefined) {
    return selectedKey;
  }
  if (!event) {
    return "";
  }
  let returnValue = null;
  const target = event.target;

  if (target.type === "checkbox") {
    returnValue = target.checked;
  } else {
    returnValue = target.value ? target.value : "";
  }

  return returnValue;
}

export const parseAdvancedTitle = (advancedTitle, replacementMap) => {
  let parsedTitle = [];
  for (let i = 0; i < advancedTitle.length; i++) {
    let titleAdv = { ...advancedTitle[i] };
    for (var key in titleAdv) {
      if (!titleAdv.hasOwnProperty(key) || (typeof titleAdv[key] !== "string") || titleAdv.keepLeadingAndTrailingSpaces) continue;
      titleAdv[key] = getLocalizedProperty(titleAdv, key, titleAdv[key], replacementMap);
    }
    parsedTitle.push(<GenericInput
      key={i}
      {...titleAdv}
      avoidContainer={true}
    />);
  }
  return parsedTitle;
}

export const parseString = (string, object, formatterFieldMap, optionalObject) => {
  return StringUtils_parseString(string, object, formatterFieldMap, optionalObject);
}

export const parseStringFromApplyFilters = (string, applyFilters, replaceWithNull = true) => {
  return StringUtils_parseStringFromApplyFilters(string, applyFilters, replaceWithNull);
}

export const parseConditionString = (string, object, formatterFieldMap, replaceWithNull = true) => {
  return StringUtils_parseConditionString(string, object, formatterFieldMap, replaceWithNull);
}

export const parseConditionStringFromApplyFilters = (string, applyFilters) => {
  return StringUtils_parseConditionStringFromApplyFilters(string, applyFilters);
}

export const textContains = (search, text, caseSensitive) => {
  return StringUtils_textContains(search, text, caseSensitive);
}

export const findIconName = (value) => {
  return StringUtils_findIconName(value);
}

export const clickLinkHandler = (path, props, component, dataObject) => {
  if (path.startsWith("http") || props.external) {
    window.open(path);
  } else {
    let object = dataObject != null ? dataObject : component.state.object;
    const destinationTitle = getLocalizedProperty(props, "destinationTitle", props.destinationTitle, object);
    if (object == null) {
      const currentState = component.context != null && component.context.location != null ? component.context.location.state : component.props.location.state;
      if (props.viewStateAttribute && currentState[component.props.viewStateAttribute]) {
        object = currentState[component.props.viewStateAttribute].applyFilters.reduce((returnValue, currentValue, currentIndex) => {
          returnValue[currentValue.param] = currentValue.value;
          return returnValue;
        }, {});
      }
    }
    const filters = props.filters ? props.filters.map((filter) => {
      const newFilter = { ...filter };
      // eslint-disable-next-line 
      newFilter.value = eval("object." + filter.field);
      return newFilter;
    }) : null;
    let state;
    if (props.mantainApplyFilters == null || props.mantainApplyFilters === false) {
      state = {
        filters: [],
        applyFilters: filters,
        destinationTitle: destinationTitle,
        page: 0,
        fromLink: true
      };
    } else {
      if (component.context != null && component.context.location != null) {
        state = Object.assign({}, component.context.location.state);
      } else {
        state = Object.assign({}, component.props.location.state);
      }
      state[props.viewStateAttribute] = {
        filters: [],
        applyFilters: filters,
        destinationTitle: destinationTitle,
        page: 0,
        fromLink: props.fromLink
      };
    }

    const to = {
      pathname: path,
      state: state
    };
    if (component.props.history != null) {
      component.props.history.push(to);
    } else if (component.context?.history != null) {
      component.context.history.push(to);
    }
  }
}

export const evaluateShowIfOnComponent = (condition, applyFilters, replacementMap) => {
  if (condition && applyFilters) {
    let evaluation = false;
    if (replacementMap) {
      let calculatedApplyFilters = [...applyFilters];
      calculatedApplyFilters.push(replacementMap);
      // eslint-disable-next-line 
      evaluation = eval(parseConditionStringFromApplyFilters(condition, calculatedApplyFilters));
    } else {
      // eslint-disable-next-line 
      evaluation = eval(parseConditionStringFromApplyFilters(condition, applyFilters));
    }
    return evaluation;
  } else if (condition && !applyFilters) {
    try {
      // eslint-disable-next-line 
      let evaluation = eval(condition);
      return evaluation
    } catch (e) {
      console.error("Condizione errata: " + condition);
      return false;
    }

  } else {
    return true;
  }
}

export const copyTextToClipboard = (text) => {
  if (window.clipboardData) {
    window.clipboardData.setData("Text", text);
  } else if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(text).then(() => {
      const message = buildMessage(MessageSeverityEnum.SUCCESS, "copyTextToClipboard.SUCCESS", { text: text });
      showMessageModal(true, null, message, false);
    }, () => {
      const message = buildMessage(MessageSeverityEnum.WARNING, "copyTextToClipboard.WARNING", { text: text });
      showMessageModal(true, null, message, true);
    });
  } else {
    const message = buildMessage(MessageSeverityEnum.WARNING, "copyTextToClipboard.WARNING", { text: text });
    showMessageModal(true, null, message, true);
  }
}

export const computeFunctionBody = (functionBody) => {
  let funcBody = functionBody;
  if (funcBody.indexOf("return ") === -1) {
    funcBody = "return " + funcBody;
  }

  return funcBody;
}

export const computeCalculatedValueAdditionalObject = (functionParameters, props) => {
  const additionalObjectResult = {
    name: null,
    object: null
  };

  // recupero e costruisco oggetto aggiuntivo da passare alla funzione
  if (functionParameters?.name != null && functionParameters?.type != null) {
    const name = functionParameters.name;
    const type = functionParameters.type;
    let object = null;

    if (type === "flatObject") {
      object = props[name];
    } else if (type === "complexObject" && Array.isArray(functionParameters.attributes) === true) {
      object = {};

      functionParameters.attributes.forEach(attribute => {
        if (attribute != null) {
          object[attribute] = props[attribute];
        }
      });
    } else {
      console.warn("Undefined case for type[" + type + "] of calculatedValute");
    }

    additionalObjectResult.name = name;
    additionalObjectResult.object = object;
  }

  return additionalObjectResult;
}

export const retrieveCalculatedValue = (calculateValueFunction, object, field, additionalObjectName, additionalObject) => {
  let fieldValue = "";

  if (calculateValueFunction != null) {
    try {
      const funcBody = computeFunctionBody(calculateValueFunction);
      const func = additionalObjectName != null
        // eslint-disable-next-line
        ? new Function("object", "functions", additionalObjectName, funcBody)
        // eslint-disable-next-line
        : new Function("object", "functions", funcBody);

      const functions = {
        useFormatter: (value, formatterName, formatterParameters) => {
          return formatters[formatterName](value, formatterParameters, object);
        },
        isNullOrEmpty: isNullOrEmpty,
        isNotNullOrEmpty: isNotNullOrEmpty
      };

      fieldValue = func(object == null ? {} : object, functions, additionalObject);
    } catch (e) {
      console.error(e.message + " RETRIEVECALCULATEDVALUE Probabile errore di sinstassi " + JSON.stringify(calculateValueFunction));
    }
  } else {
    try {
      // eslint-disable-next-line 
      fieldValue = eval("object." + field);
      if (fieldValue === null || fieldValue === undefined) {
        fieldValue = "";
      }
    } catch (ex) {
    };
  }

  return fieldValue;
}

export const processFunctionResult = (result, defaultErrorMessage) => {
  let isValid = true;
  let errorMessage = "";

  switch (typeof result) {
    case "boolean":
      isValid = result;
      errorMessage = result ? "" : defaultErrorMessage;//"FormValidators.genericConditionValidator.ValidationFailed"
      break;
    case "string":
      errorMessage = result;
      isValid = result === "";
      break;
    case "number":
      isValid = result !== 0;
      errorMessage = isValid ? "" : defaultErrorMessage;//"FormValidators.genericConditionValidator.ValidationFailed"
      break;
    default:
      isValid = false;
      errorMessage = isValid ? "" : defaultErrorMessage;//"FormValidators.genericConditionValidator.ValidationFailed"
      break;
  }

  return [isValid, errorMessage];
}

export const computeDependsOnFieldsUrl = (url, dependsOnFields) => {
  const returnObject = {
    url: url,
    isFilterPresent: false
  };

  if (!Array.isArray(dependsOnFields)) {
    return returnObject;
  };

  dependsOnFields.forEach(dependsOnField => {
    if (dependsOnField.value != null && dependsOnField.value !== "" && dependsOnField.value !== "EQ()") {
      if (Array.isArray(dependsOnField.value)) {
        dependsOnField.value.forEach((value) => {
          returnObject.url += "&" + encodeURIComponent(dependsOnField.param) + "=" + encodeURIComponent(value);
        })
      } else {
        returnObject.url += "&" + encodeURIComponent(dependsOnField.param) + "=" + encodeURIComponent(dependsOnField.value);
      }
      returnObject.isFilterPresent = true;
    }
  });

  return returnObject;
}


export const customLabelRender = (props, defaultRender) => {
  let label = getLocalizedProperty(props, "label", null, props.object);
  if (defaultRender == null) {
    if (label != null && label !== "") {
      label = (<Label disabled={props.disabled} required={props.required}>{label}</Label>);
    }
  } else {
    label = defaultRender({ ...props, label: label });
  }
  let errorMessage = "";
  if (props.formFeedBack != null && props.formFeedBack !== "") {
    errorMessage = props.formFeedBack;
  } else if (props.errorMessage != null && props.errorMessage !== "") {
    errorMessage = props.errorMessage;
  }

  let warningMessage = "";
  if (errorMessage === "" || errorMessage == null) {
    if (props.warningMessage != null && props.warningMessage !== "") {
      warningMessage = props.warningMessage;
    }
  }

  return (
    <ErrorLabel
      label={label}
      errorMessage={errorMessage}
      warningMessage={warningMessage}
    />
  );
};

export const refreshDataTables = (dataTableIdsToRefresh, object, isSendToAll) => {
  const wagxBroadcastChannel = isSendToAll === true && window.wagxBroadcastChannelSendToAll != null
    ? window.wagxBroadcastChannelSendToAll
    : window.wagxBroadcastChannel;

  if (wagxBroadcastChannel != null) {
    if (dataTableIdsToRefresh && Array.isArray(dataTableIdsToRefresh)) {
      dataTableIdsToRefresh.forEach(item => {
        if (!isNaN(item)) {
          wagxBroadcastChannel.postMessage({ wagxCommand: "refresh", viewId: item });
        } else {

          let objectId = null;
          if (item.objectId != null) {
            objectId = _.get(object, item.objectId, null);
          }

          wagxBroadcastChannel.postMessage({ wagxCommand: "refresh", viewId: item.viewId, objectId: objectId });
        }
      });
    }
    return true;
  } else {
    return false;
  }
}

export const openViewInModal = (actionProps, object, props) => {
  const viewId = actionProps.viewIdToOpenInDialog;
  if (viewId == null) {
    console.log("VIEW ID NOT SPECIFIED", actionProps);
    return;
  }

  const modalStyle = actionProps.modalStyle != null ? actionProps.modalStyle : null;
  const objectIdField = actionProps.objectIdField;
  let loadedObject = _.cloneDeep(actionProps.loadedObject);

  let objectIdValue = "New";
  if (objectIdField != null) {
    if (Array.isArray(objectIdField)) {
      objectIdValue = "";
      objectIdField.forEach(field => {
        objectIdValue += _.get(object, field, null);
        objectIdValue += "_";
      });
      objectIdValue = objectIdValue.slice(0, -1);
    } else {
      objectIdValue = _.get(object, objectIdField, null);
    }
  }

  if (loadedObject != null) {
    for (const key in loadedObject) {
      loadedObject[key] = _.get(object, loadedObject[key]);
    }
  }

  const staticObject = _.cloneDeep(actionProps.staticObject);
  if (staticObject != null) {
    if (loadedObject == null) {
      loadedObject = {};
    }
    for (const key in staticObject) {
      loadedObject[key] = staticObject[key];
    }
  }

  let newApplyFilters = [];

  if (actionProps.applyFiltersParam != null && objectIdValue != null) {
    newApplyFilters.push({ "param": actionProps.applyFiltersParam, "value": "EQ(" + objectIdValue + ")" });
  }
  if (Array.isArray(actionProps.applyFilters) === true) {
    const applyFilters = _.cloneDeep(actionProps.applyFilters);
    applyFilters.forEach((applyFilter) => {
      const newApplyFilter = computeApplyFilter(applyFilter, object);
      newApplyFilters.push(newApplyFilter);
    });
  }

  const updatedState = Object.assign({}, props.location.state);
  updatedState["SearchResultModal"] = {
    applyFilters: newApplyFilters,
    destinationTitle: isNotNullOrEmpty(actionProps.destinationTitle) ? getLocalizedProperty(actionProps, "destinationTitle", actionProps.destinationTitle, object) : null,
    page: 0,
    filters: []
  }

  

  props.history.replace(
    props.location.pathname,
    updatedState
  );

  props.showSearchModal(actionProps.viewIdToOpenInDialog, modalStyle, objectIdValue, loadedObject);
}

const SimpleConditionType = {
  EQ: "EQ", NEQ: "NEQ", SW: "SW"
}

export const computeApplyFilter = (filter, object) => {
  let value = _.get(object, filter.value, filter.value);
  if (filter.operator != null) {
    value = SimpleConditionType[filter.operator] + "(" + value + ")";
  }

  return { param: filter.param, value: value };
}

export const customSendBeacon = (url, body = {}) => {
  const headers = {
    type: 'application/json'
  };
  const blob = new Blob([JSON.stringify(body)], headers);

  navigator.sendBeacon(url, blob);
}

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes == null || bytes === 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

// eslint-disable-next-line
String.prototype.replaceInsensitive = function (strToReplace, replaceWith) {
  var strLower = this.toLowerCase();
  var findLower = String(strToReplace).toLowerCase();
  var strTemp = this.toString();
  var pos = strLower.length;
  while ((pos = strLower.lastIndexOf(findLower, pos)) !== -1) {
    strTemp = strTemp.substr(0, pos) + replaceWith + strTemp.substr(pos + findLower.length);
    pos--;
  }
  return strTemp;
};

/**
 * @deprecated usare il metodo computeApplyFiltersParam perché il nome è stato corretto
 */
export const computeApplyFiltrersParam = (actionProps, object, objectId, viewStateApplyFilters) => {
  return computeApplyFiltersParam(actionProps, object, objectId, viewStateApplyFilters)
}

export const computeApplyFiltersParam = (actionProps, object, objectId, viewStateApplyFilters) => {
  let applyFilters = [];

  if (actionProps == null || actionProps.applyFiltersParam == null) {
    return applyFilters;
  }

  if (Array.isArray(actionProps.applyFiltersParam)) {
    applyFilters = actionProps.applyFiltersParam.map((filter) => {
      let filterToApply = {};

      if (_.get(object, filter.value, null) != null) {
        filterToApply.param = filter.param;
        filterToApply.value = filter.isEqual === true ? "EQ(" + _.get(object, filter.value, null) + ")" : _.get(object, filter.value, null);
      } else if (Array.isArray(viewStateApplyFilters)) {
        const matchedFilter = viewStateApplyFilters.find((viewStateFilter) => {
          return viewStateFilter.param === filter.value;
        });

        if (matchedFilter != null) {
          filterToApply = { ...matchedFilter };
          filterToApply.param = filter.param;
        }
      }

      return filterToApply;
    });
  } else {
    applyFilters = [{ "param": actionProps.applyFiltersParam, "value": actionProps.isEquals ? "EQ(" + objectId + ")" : objectId }]
  }

  return applyFilters;
}

export const showViewNotFoundModal = (viewId, invokeHideSearchModal) => {
  console.error("View with id [" + viewId + "] not found. Check view permission!");

  if (invokeHideSearchModal === true) {
    store.dispatch(actions.hideSearchModal());
  }

  const message = buildMessage(MessageSeverityEnum.INFO, "NotFound.text");
  showMessageModal(false, null, message, true);
}

export const showConfirmModal = (success, title, messageList, permanent, timeout, onConfirmHandler, onDismissedHandler, confirmText, cancelText) => {
  const responseMessageObject = manageResponseDataMessageList(success, messageList);

  store.dispatch(actions.showConfirmModal({
    messageSeverityEnum: responseMessageObject.messageSeverityEnum,
    title: title,
    messageList: responseMessageObject.messageList,
    permanent: permanent,
    timeout: timeout,
    onConfirmHandler: onConfirmHandler,
    onDismissedHandler: onDismissedHandler,
    confirmText: confirmText,
    cancelText: cancelText
  }));

  return responseMessageObject;
}

export const showMessageModal = (success, title, messageList, permanent, timeout, onDismissedHandler) => {
  const responseMessageObject = manageResponseDataMessageList(success, messageList);

  store.dispatch(actions.showModal({
    messageSeverityEnum: responseMessageObject.messageSeverityEnum,
    title: title,
    messageList: responseMessageObject.messageList,
    permanent: permanent,
    timeout: timeout,
    onDismissedHandler: onDismissedHandler
  }));

  return responseMessageObject;
}

export const manageResponseDataMessageList = (success, messageList, avoidHtml) => {
  const responseMessageObject = {
    messageSeverityEnum: MessageSeverityEnum.INFO,
    messageList: []
  };

  let messageToParseList = [];
  if (Array.isArray(messageList) === true && messageList.length > 0) {
    messageToParseList = messageList;
  } else if (messageList != null && messageList.messageKey != null) {
    messageToParseList.push(messageList);
  } else {
    let message = success === true
      ? buildMessage(MessageSeverityEnum.SUCCESS)
      : buildMessage(MessageSeverityEnum.ERROR);

    messageToParseList.push(message);
  }

  messageToParseList.forEach((messageToParse) => {

    if (messageToParse.messageKey == null || messageToParse.messageKey === "") {
      return;
    }

    const message = avoidHtml === true
      ? intl.get(messageToParse.messageKey, messageToParse.parameters).d(messageToParse.messageKey)
      : intl.getHTML(messageToParse.messageKey, messageToParse.parameters).d(messageToParse.messageKey);
    responseMessageObject.messageList.push(message);

    if (messageToParse.severity == null) {
      messageToParse.severity = success === true ? MessageSeverityEnum.SUCCESS.key : MessageSeverityEnum.ERROR.key;
    }

    const messageSeverityEnum = getMessageSeverityEnumByKey(messageToParse.severity);
    if (messageSeverityEnum.priority < responseMessageObject.messageSeverityEnum.priority) {
      responseMessageObject.messageSeverityEnum = messageSeverityEnum;
    }

  });

  if (responseMessageObject.messageList.length === 0) {
    const messageKey = responseMessageObject.messageSeverityEnum.messageKey;
    const message = avoidHtml === true
      ? intl.get(messageKey).d(messageKey)
      : intl.getHTML(messageKey).d(messageKey);
    responseMessageObject.messageList.push(message);
  }

  return responseMessageObject;
}

export const buildMessage = (messageSeverityEnum, messageKey, parameters) => {
  let severity = messageSeverityEnum;
  if (severity == null) {
    severity = MessageSeverityEnum.INFO;
  }

  const message = {
    severity: severity.key,
    messageKey: messageKey == null || messageKey === "" ? severity.messageKey : messageKey,
    parameters: parameters
  };

  return message;
}

export const getDashIfEmptyString = (str, dash = '-') => {
  if (str != null && ("" + str).trim() !== "") {
    return str;
  } else {
    return dash;
  }
}

export const isHTML = (string) => {
  if (string == null || string === "") {
    return false;
  }

  const doc = new DOMParser().parseFromString(string, "text/html");
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

export const isEmpty = (value) => {
  return StringUtils_isEmpty(value);
}

export const isNotEmpty = (value) => {
  return StringUtils_isNotEmpty(value);
}

export const isNullOrEmpty = (value) => {
  return StringUtils_isNullOrEmpty(value);
}

export const isNotNullOrEmpty = (value) => {
  return StringUtils_isNotNullOrEmpty(value);
}

export const generateArrayFilters = (element) => {
  let value;
  switch (element.filterType) {
    case "BW": //BETWEEN
      if (Array.isArray(element.value)) {
        if (element.value[0] === null || element.value[0] === undefined || element.value[0] === "") {
          value = "LT(" + element.value[1] + ")";
        } else if (element.value[1] === null || element.value[1] === undefined || element.value[1] === "") {
          value = "GT(" + element.value[0] + ")";
        } else {
          value = "BW(" + element.value[0] + ";" + element.value[1] + ")";
        }
      } else {
        value = "EQ(" + element.value + ")";
      }
      break;
    case "IN":
      if (Array.isArray(element.value)) {
        let i;
        value = "IN(";
        for (i = 0; i < element.value.length; i++) {
          if (element.value[i] != null) {
            value += element.value[i] + ";";
          }
        }
        value = value.substring(0, value.length - 1) + ")";
      } else {
        value = "EQ(" + element.value + ")";
      }
      break;
    case "EQ":
      if (Array.isArray(element.value)) {
        value = "EQ(" + element.value[0] + ")";
      } else {
        value = "EQ(" + element.value + ")";
      }
      break;
    case "NEQ":
      if (Array.isArray(element.value)) {
        value = "NEQ(" + element.value[0] + ")";
      } else {
        value = "NEQ(" + element.value + ")";
      }
      break;
    case "GT": //GREATER THEN EQUALS (>=)
      if (Array.isArray(element.value)) {
        value = "GT(" + element.value[0] + ")";
      } else {
        value = "GT(" + element.value + ")";
      }
      break;
    case "LT": //LESS THEN EQUALS (<=)
      if (Array.isArray(element.value)) {
        value = "LT(" + element.value[0] + ")";
      } else {
        value = "LT(" + element.value + ")";
      }
      break;
    default:
      if (Array.isArray(element.value)) {
        if (element.value.length === 1) {
          value = "EQ(" + element.value[0] + ")";
        } else if (element.value.length > 2) {
          value = "IN(" + element.value[0];
          for (let i = 1; i < element.value.length; i++) {
            if (element.value[i] != null) {
              value += ";" + element.value[i];
            }
          }
          value += ")";
        } else if (element.value.length === 2) {
          if (element.value[0] === null || element.value[0] === undefined || element.value[0] === "") {
            value = "LT(" + element.value[1] + ")";
          } else if (element.value[1] === null || element.value[1] === undefined || element.value[1] === "") {
            value = "GT(" + element.value[0] + ")";
          } else {
            value = "BW(" + element.value[0] + ";" + element.value[1] + ")";
          }
        }
      } else {
        value = "EQ(" + element.value + ")";
      }
      break;
  }
  return value;
}

export const getBlob = (fileBody, contentType) => {
  const byteCharacters = atob(fileBody);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}