import loadash from 'lodash';
import { Checkbox, DefaultButton, Icon, IconButton, Label, Rating, RatingSize, Slider, TextField, Toggle, TooltipHost } from 'office-ui-fabric-react';
import React, { Component, Suspense } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ViewContext from "../../containers/ViewContext/ViewContext";
import Dynamic from '../../Dynamic';
import * as formatters from '../../functions/formatters';
import * as evaluateFunctions from '../../functions/utility';
import { getLocalizedProperty } from '../../LocaleUtils';
import * as actions from '../../store/actions';
import DatePicker from '../../UI/DatePicker/DatePicker';
import DateRangePicker from '../../UI/DatePicker/DateRangePicker';
import RadioButton from '../../UI/RadioButton/RadioButton';
import SelectField from '../../UI/SelectField/SelectField';
import Spinner from '../../UI/Spinner/Spinner';
import WagxAceEditor from '../../UI/WagxAceEditor/WagxAceEditor';
import WagxTextArea from '../../UI/WagxTextArea/WagxTextArea';
import DateTimePicker from '../../UI/DateTimePicker/DateTimePicker';
import ContextMenu from '../ContextMenu/ContextMenu';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import DataTable from '../DataTable/DataTable';
import FormSubmit from '../FormSubmit/FormSubmit';
import * as ButtonActions from "../GenericForm/ButtonActions/ButtonActions";
import GooglePlacesAutocomplete from '../GooglePlacesAutocomplete/GooglePlacesAutocomplete';
import TextWithBorder from '../TextWithBorder/TextWithBorder';
import WagxAdvSelectField from '../WagxAdvSelectField/WagxAdvSelectField';
import WagxAutocomplete from '../WagxAutocomplete/WagxAutocomplete';
import WagxCalculatedInput from '../WagxCalculatedInput/WagxCalculatedInput';
import WagxFileDropZone from '../WagxFileDropZone/WagxFileDropZone';
import WagxLink from '../WagxLink/WagxLink';
import WagxWhiteSpace, { calculatePreAndPostSpaces } from '../WagxWhiteSpace/WagxWhiteSpace';
import './GenericInput.css';
import * as GenericInputTypes from './GenericInputTypes';
import lodash from 'lodash';
import CurrencyRangeInput from '../CurrencyInput/CurrencyRangeInput';
import WagxEditor from '../WagxEditor/WagxEditor';
import { generateGridColumnSizeClassName } from '../WagxGenericForm/WagxGenericFormUtil';

class GenericInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false
    };

    this.value = null;
  }

  onFocusHandler = (isFocused) => {
    this.setState({ isFocused: isFocused }, () => {
      if (this.state.isFocused === false && this.props.formatter) {
        const value = formatters[this.props.formatter](this.props.value);
        if (value !== this.props.value) {
          this.props.onChange(null, this.props.field, value, null, this.props.formId);
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.value !== this.value &&
      (
        this.props.type === GenericInputTypes.TEXT ||
        (this.props.fireOnChangeOnDidMount === true && [GenericInputTypes.TOGGLE].indexOf(this.props.type) !== -1)
      )
    ) {
      // Necessario per scatenare l'onchange sui set di valori ricevuti dal padre e non cambiati direttamente dall'utente.
      // Es. valore errato sul db letto dal GenericForm
      this.value = this.props.value;
      this.props.onChange(null, this.props.field, this.props.value, null, this.props.formId);
    }
  }

  onChangeHandler = (event) => {
    this.value = evaluateFunctions.getValueFromInput(event);
    if (this.props.disabled == null || !this.props.disabled) {
      this.props.onChange(event, this.props.field, null, null, this.props.formId);
    }
  }

  getViewValue() {
    let value = this.props.value == null ? "" : this.props.value;
    return value;
  }

  onPasteHandler = (e) => {
    try {
      const start = Math.min(e.target.selectionStart, e.target.selectionEnd);
      const end = Math.max(e.target.selectionStart, e.target.selectionEnd);
      let val = this.props.value;
      const prefix = val.substring(0, start);
      const suffix = val.substring(end);
      if (e.clipboardData && e.clipboardData.getData) {
        var pastedText = "";
        if (window.clipboardData && window.clipboardData.getData) { // IE
          pastedText = window.clipboardData.getData('Text');
        } else if (e.clipboardData && e.clipboardData.getData) {
          pastedText = e.clipboardData.getData('text/plain');
        }
        const trimmedPastedText = pastedText.trim();
        const newVal = prefix + trimmedPastedText + suffix;
        const ev = { target: { value: newVal } };

        this.props.onChange(ev, this.props.field, null, null, this.props.formId);
        e.preventDefault();
        window.event.cancelBubble = true;
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        // Modo un po' brutto ma non ho trovato di meglio per riposizionare il cursore nella posizione corretta dopo che si e' incollato  un testo su una selezione o in mezzo al testo
        const t = e.target;
        setTimeout(() => {
          try {
            t.value = newVal;
            const caretPos = Math.min((start + trimmedPastedText.length), newVal.length);
            if (t.createTextRange) {
              var range = t.createTextRange();
              range.move('character', caretPos);
              range.select();
            } else if (t.selectionStart) { // IE
              t.focus();
              t.setSelectionRange(caretPos, caretPos);
            }
          } catch (e) {
            // Give up!
          }
        }, 50);
      }
    } catch (e) {
      // Give up!
    }
  }

  executeArrayOfActions = (arr, i, component, actionProps, value) => {
    if (i < arr.length) {
      const actionResult = ButtonActions[arr[i]](component, actionProps, value);
      if (actionResult != null && typeof actionResult.then === "function") {
        actionResult.then((response) => {
          if (response.data != null) {
            if (response.data.success === true) {
              this.executeArrayOfActions(arr, ++i, component, actionProps, value);
            } else {
              evaluateFunctions.showMessageModal(response.data.success, null, response.data.responseMessages, true);
            }
          } else {
            this.executeArrayOfActions(arr, ++i, component, actionProps, value);
          }
        });
      } else {
        this.executeArrayOfActions(arr, ++i, component, actionProps, value);
      }
    }
  }

  render() {
    const props = this.props;
    let input;
    const value = props.value === "null" || props.value === null ? "" : props.value;
    const placeHolderClass = value && value !== "" ? "" : "PlaceHolder";
    let parsedLabel = getLocalizedProperty(props, "label", null, this.props.object);
    let dependsOnFields = [];
    if (props.dependsOnFields != null) {
      dependsOnFields = props.dependsOnFields.map(dependsOnField => {
        let parsedValue = loadash.get(props.object, dependsOnField.field, null);
        if (parsedValue && dependsOnField.isEquals && Array.isArray(parsedValue) === false) {
          parsedValue = "EQ(" + parsedValue + ")";
        }
        return { param: dependsOnField.param, value: parsedValue };
      });
    }

    const placeholder = getLocalizedProperty(props, 'placeholder', props.placeholder);
    const toolTip = getLocalizedProperty(props, 'tooltip', placeholder, props.object);

    switch (props.type) {
      case GenericInputTypes.TEXT_NO_UPDATE:
        if (this.props.objectId != null) {
          input = (
            <TooltipHost content={toolTip} id={this.hostId}>
              <TextField
                id={"inp-" + props.field}
                label={parsedLabel}
                onRenderLabel={evaluateFunctions.customLabelRender}
                name={props.field}
                className={"TextInputReadOnly"}
                value={(formatters[props.formatter] ? formatters[props.formatter](this.getViewValue(), props.formatterParameters, props.object) : this.getViewValue())}
                suffix={props.inputSuffix}
                readOnly={true}
                maxLength={props.maxLength}
              />
            </TooltipHost>
          );
        }
        break;
      case GenericInputTypes.TEXT:
        input = (
          <TooltipHost content={toolTip} id={this.hostId}>
            <TextField
              disabled={this.props.disabled}
              errorMessage={props.formFeedBack}
              id={"inp-" + props.field}
              label={parsedLabel}
              onRenderLabel={evaluateFunctions.customLabelRender}
              name={props.field}
              className={placeHolderClass}
              value={this.getViewValue()}
              placeholder={placeholder}
              onChange={this.onChangeHandler.bind(this)}
              onFocus={() => this.onFocusHandler(true)}
              onBlur={() => this.onFocusHandler(false)}
              onPaste={this.props.cleanupOnPaste === false ? null : this.onPasteHandler}
              required={props.required}
              suffix={props.inputSuffix}
              readOnly={props.forcedValue ? true : false}
              defaultValue={props.forcedValue ? props.forcedValue : null}
              maxLength={props.maxLength}
              type={props.type}
            />
          </TooltipHost>
        );
        break;
      case GenericInputTypes.NUMBER:
        input = (
          <TooltipHost content={toolTip} id={this.hostId}>
            <TextField
              disabled={this.props.disabled}
              errorMessage={props.formFeedBack}
              id={"inp-" + props.field}
              label={parsedLabel}
              onRenderLabel={this.customLabelRender}
              name={props.field}
              className={placeHolderClass}
              value={this.getViewValue()}
              placeholder={getLocalizedProperty(props, 'placeholder')}
              onChange={this.onChangeHandler.bind(this)}
              onFocus={() => this.onFocusHandler(true)}
              onBlur={() => this.onFocusHandler(false)}
              onPaste={this.props.cleanupOnPaste === false ? null : this.onPasteHandler}
              required={props.required}
              readOnly={props.readOnly === true ? true : props.forcedValue ? true : false}
              defaultValue={props.forcedValue ? props.forcedValue : null}
              maxLength={props.maxLength}
              type={props.type}
              min={props.min == null ? '0' : props.min}
              max={props.max == null ? '9999999' : props.max}
            />
          </TooltipHost>
        );
        break;
      case GenericInputTypes.CURRENCY:
        input = (
          <TooltipHost content={toolTip} id={this.hostId}>
            <CurrencyInput
              disabled={this.props.disabled}
              formFeedBack={this.props.formFeedBack}
              field={this.props.field}
              cleanupOnPaste={this.props.cleanupOnPaste}
              deferredValidationTime={this.props.deferredValidationTime}
              required={this.props.required}
              inputSuffix={this.props.inputSuffix}
              maxLength={this.props.maxLength}
              parsedLabel={parsedLabel}
              customLabelRender={evaluateFunctions.customLabelRender.bind(this)}
              placeholder={placeholder}
              placeHolderClass={placeHolderClass}
              onPasteHandler={this.onPasteHandler.bind(this)}
              onChange={this.props.onChange}
              value={this.props.value}
              formId={this.props.formId}
              formatter={this.props.formatter}
              allowNegativeValue={this.props.allowNegativeValue}
            />
          </TooltipHost>
        );
        break;
      case GenericInputTypes.CURRENCY_RANGE:
        input = (
          <CurrencyRangeInput {...props} />
        );
        break;
      case GenericInputTypes.DATE:
        input = (
          <TooltipHost content={toolTip} id={this.hostId}>
            <DatePicker
              {...props}
              disabled={this.props.disabled}
              label={getLocalizedProperty(props, 'label')}
              onRenderLabel={(props, defaultRender) => evaluateFunctions.customLabelRender(this.props, defaultRender)}
              placeholder={placeholder}
              value={formatters[props.formatter](value)}
              allowTextInput={this.props.allowTextInput == null ? true : this.props.allowTextInput}
              minDateField={this.props.minDateField}
              minDate={this.props.minDate}
              maxDate={this.props.maxDate}
            />
          </TooltipHost>
        );
        break;
      case GenericInputTypes.DATE_RANGE:
        input = (
          <DateRangePicker {...props} />
        );
        break;
      case GenericInputTypes.SELECT:
        let lovId = props.lovId;
        let lovValue = props.lovValue;
        if (props.preloadedLovs && props.preloadedLovs[lovId]) {
          if (props.lovValue) {
            lovValue = [...props.preloadedLovs[lovId], ...props.lovValue];
          } else {
            lovValue = [...props.preloadedLovs[lovId]];
          }
          lovId = null;
        }
        input = (
          <TooltipHost content={toolTip} id={this.hostId}>
            <SelectField
              multiSelect={props.multiSelect}
              collapseSelectedItemsText={this.props.collapseSelectedItemsText}
              useLiveSearch={props.useLiveSearch}
              manageExtraFields={props.manageExtraFields}
              invalid={props.valid === false}
              formFeedBack={props.formFeedBack}
              value={formatters[props.formatter](value)}
              lovId={lovId}
              lovValue={lovValue}
              fieldType={props.fieldType}
              placeholder={placeholder}
              label={getLocalizedProperty(props, 'label')}
              onRenderLabel={evaluateFunctions.customLabelRender}
              name={props.field}
              onChange={props.onChange}
              applyFilters={props.applyFilters}
              remapAppliedFiltersFields={props.remapAppliedFiltersFields}
              required={props.required}
              forcedValue={props.forcedValue}
              disabled={props.disabled}
              dependsOnFields={dependsOnFields}
              onSelectFieldBeforeLoadData={props.onSelectFieldBeforeLoadData}
              onSelectFieldLoadData={props.onSelectFieldLoadData}
              callOnlyWhenFilterIsPresent={props.callOnlyWhenFilterIsPresent}
              formId={this.props.formId}
              fillValue={this.props.fillValue}
              onMenuDismissedHandler={this.props.onMenuDismissedHandler}
              defaultSelectedIndex={this.props.defaultSelectedIndex}
              defaultValue={this.props.defaultValue}
              handleLovIdFilters={this.props.handleLovIdFilters}
              defaultSelectedFirst={this.props.defaultSelectedFirst}
              onExceptionHandler={this.props.onExceptionHandler}
              refreshInterval={this.props.refreshInterval}
              invokeOnChangeOnLoad={this.props.invokeOnChangeOnLoad}
              translationKey={this.props.translationKey}
            />
          </TooltipHost>
        );
        break;
      case GenericInputTypes.TEXTAREA:
        input = (
          <WagxTextArea
            disabled={props.disabled}
            formFeedBack={props.formFeedBack}
            id={"inp-" + props.field}
            resizable={props.resizable}
            label={getLocalizedProperty(props, 'label')}
            onRenderLabel={evaluateFunctions.customLabelRender}
            name={props.field}
            className={placeHolderClass}
            value={formatters[props.formatter](value)}
            placeholder={placeholder}
            onChange={(event, value) => this.props.onChange(event, this.props.field, value)}
            multiline={true}
            rows={props.rows}
            allowFullScreen={true}
            required={props.required}
            autoAdjustHeight={this.props.autoAdjustHeight}
          />
        );
        break;
      case GenericInputTypes.CODE:
        input = (
          <React.Fragment>
            <label>{props.label}</label>
            <WagxAceEditor
              objectId={this.props.objectId}
              value={formatters[props.formatter](value)}
              mode="json"
              theme="xcode"
              name={"aceEditorUniqueName_" + this.props.field}
              rows={props.rows}
              onChange={(event, errors) => { this.props.onChange({ target: { value: event } }, this.props.field, event, errors) }}
              updateViewOnRedux={this.props.updateViewOnRedux}
            />
          </React.Fragment>
        );
        break;
      case GenericInputTypes.CSSEDITOR:
        input = (
          <React.Fragment>
            <label>{props.label}</label>
            <WagxAceEditor
              objectId={this.props.objectId}
              value={formatters[props.formatter](value)}
              mode="css"
              theme="xcode"
              name={"aceEditorUniqueName_" + this.props.field}
              rows={props.rows}
              onChange={(event, errors) => { this.props.onChange({ target: { value: event } }, this.props.field, event, null) }}
            />
          </React.Fragment>
        );
        break;
      case GenericInputTypes.CHECKBOX:
        input = (
          <Checkbox id={"inp-" + props.field}
            invalid={props.valid === false}
            name={props.field}
            checked={props.value ? props.value : false}
            value={props.value}
            disabled={props.disabled}
            onChange={(event) => props.onChange(event, props.field)}
            label={props.label}
          />
        );
        break;
      case GenericInputTypes.RADIO:
        input = (
          <RadioButton
            onChange={props.onChange}
            className={props.className ? props.className : ""}
            defaultSelectedKey={props.defaultSelectedKey ? props.defaultSelectedKey : null}
            required={props.required ? props.required : false}
            label={props.label}
            options={props.lovValue ? props.lovValue : props.value}
            lovId={props.lovId}
            lovValue={props.lovValue}
          />
        );
        break;
      case GenericInputTypes.LABEL:
        let initialSpace = 0;
        let finalSpace = 0;
        if (props.keepLeadingAndTrailingSpaces) {
          const preAndPostSpaces = calculatePreAndPostSpaces(value);

          initialSpace = preAndPostSpaces[0];
          finalSpace = preAndPostSpaces[1];
        }

        const labelClassName = props.className == null ? "" : " " + evaluateFunctions.parseString(props.className, props.object);
        const labelValue = value != null && (typeof value !== "string" || (typeof value === "string" && value.trim() !== "")) ? " " + (formatters[props.formatter] ? formatters[props.formatter](value, props.formatterParameters, props.object) : value) : placeholder;
        const labelTitle = props.title == null ? labelValue : evaluateFunctions.parseString(props.title, props.object);
        const labelTooltip = toolTip == null || (typeof toolTip === "string" && toolTip.trim() === "") ? labelTitle : "";

        input = (
          <React.Fragment>
            <TooltipHost content={toolTip} id={this.hostId}>
              {parsedLabel ? <Label className={"GenericInputLabelTitle" + labelClassName} >{parsedLabel}</Label> : null}
              <Label title={labelTooltip} className={"GenericInputLabelValue" + labelClassName}>
                <WagxWhiteSpace numberOfSpaces={initialSpace} />
                {evaluateFunctions.isNullOrEmpty(labelValue) ? labelValue : ("" + labelValue).trim()}
                <WagxWhiteSpace numberOfSpaces={finalSpace} />
              </Label>
            </TooltipHost>
          </React.Fragment>
        );
        break;
      case GenericInputTypes.DATA_TABLE:
        if (this.props.object[props.field] && this.props.object[props.field].length > 0) {
          const isScrollable = this.props.isScrollable != null ? this.props.isScrollable : false;
          const pStructure = this.props.viewMap[this.props.viewId].pStructure;
          let obj = <DataTable
            forceData={true}
            id={this.props.viewId}
            actions={[]}
            pStructure={pStructure}
            isScrollable={isScrollable}
            columns={props.columns}
            dataField={props.field}
            data={this.props.object[props.field]}
            showAction={false}
            selectionEnabled={false}
            style={this.props.style}
            loadData={false}
            onChange={props.onChangeDataTableInput}
            loadDataHandler={props.loadData}
            preloadedLovs={props.preloadedLovs}
            pagination={this.props.pagination}
            rowClassName={this.props.rowClassName}
          // height={this.props.height}
          />

          input = (
            <React.Fragment>
              {obj}
            </React.Fragment>
          );
        } else {
          input = (
            <React.Fragment>
              {intl.get("GenericInput.noResultFound").d("Nessun risultato trovato")}
            </React.Fragment>
          );
        }
        break;
      case GenericInputTypes.FILE_DROP_ZONE:
        input = (
          <WagxFileDropZone
            field={props.field}
            valid={props.valid}
            errorMessage={props.formFeedBack}
            label={props.label}
            onRenderLabel={evaluateFunctions.customLabelRender}
            disabled={props.disabled}
            minSize={props.minSize}
            maxSize={props.maxSize}
            files={props.files}
            onFileInputChanged={props.onFileInputChanged}
            onChange={props.onChange}
            accept={props.accept}
            labelProps={{ ...props.labelProps }}
            viewId={props.viewId}
            fileListClassName={props.fileListClassName}
            fileListStyle={props.fileListStyle}
            dropZoneClassName={props.dropZoneClassName}
            scrollPaneProps={props.scrollPaneProps}
            objectId={props.objectId}
            maxFiles={props.maxFiles}
          />
        );
        break;
      case GenericInputTypes.FILE:
        input = (
          <React.Fragment>
            <Label {...props.labelProps} >{props.label}</Label>
            <input type="file" required={props.required} accept={props.accept} disabled={props.disabled} ref={props.fileInputRef} multiple={props.multiple} />
          </React.Fragment>
        );
        break;
      case GenericInputTypes.ACTION_BUTTON:
        let href;
        if (props.actionButtonProps) {
          if (props.actionButtonProps.href != null) {
            href = evaluateFunctions.parseString(props.actionButtonProps.href, props.object);
          }
        }
        if (props.actionProps) {
          if (props.actionProps.path) {
            props.actionProps.path = evaluateFunctions.parseString(props.actionProps.path, props.object);
          }
        }

        let objectIdValue = null;
        const objectIdField = props.objectIdField;
        if (objectIdField != null) {
          if (Array.isArray(objectIdField)) {
            objectIdValue = null;
            objectIdField.forEach(field => {
              objectIdValue += lodash.get(props.object, field, null);
              objectIdValue += "_";
            });
            objectIdValue = objectIdValue.slice(0, -1);
          } else {
            objectIdValue = objectIdValue.get(props.object, objectIdField, null);
          }
        }

        let conditionalProps = {};
        if (props.buttonAction != null) {
          if (props.confirmMessage != null) {
            conditionalProps.onClick = () => props.sendDataConfirmDialog(props.confirmMessage, () => ButtonActions[props.buttonAction](this, props.actionProps, value));
          } else {
            conditionalProps.onClick = () => ButtonActions[props.buttonAction](this, props.actionProps, objectIdValue != null ? objectIdValue : value);
          }
        } else if (props.buttonActions != null) {
          conditionalProps.onClick = () => this.executeArrayOfActions(props.buttonActions, 0, this, props.actionProps, value);
        }

        //props in caso di funzione custom
        if (this.props.onClickHandler) {
          conditionalProps.onClick = () => { this.props.onClickHandler(props) };
        }

        if (props.className) {
          conditionalProps.className = props.className;
        }

        if (props.title) {
          conditionalProps.title = getLocalizedProperty(props, "title", props.title, props.object);
        }

        let iconTitle = "";
        if (props.iconProps) {
          iconTitle = getLocalizedProperty(props.iconProps, 'title', "", props.object);
        }

        let text;
        if (props.actionButtonProps?.text) {
          text = getLocalizedProperty(props.actionButtonProps, "text", props.actionButtonProps.text, props.object);
          conditionalProps.text = text;
        } else {
          text = value;
          if ((placeholder != null && placeholder !== "") && (text == null || text === "")) {
            text = placeholder;
          }
        }

        if (props.actionButtonProps?.title) {
          conditionalProps.title = getLocalizedProperty(props.actionButtonProps, "title", props.actionButtonProps.title, props.object);
        }

        const button = (
          props.showBadge ?
            <div className="buttonActionContainer buttonActionBadgeContainer">
              {value != null ? <div className="buttonActionBadge"  {...props.badgeProps}>{props.value}</div> : null}
              {props.actionProps && props.actionProps.iconButton
                ? <IconButton label='' iconProps={{ ...props.iconProps, title: iconTitle }} {...conditionalProps} />
                : <DefaultButton label='' text={text}  {...props.actionButtonProps} href={href} disabled={props.disabled} {...conditionalProps} />}
            </div>
            :
            <div className="buttonActionContainer">
              {props.actionProps && props.actionProps.iconButton
                ? <IconButton label='' iconProps={{ ...props.iconProps, title: iconTitle }} {...conditionalProps} />
                : <DefaultButton label='' text={text} {...props.actionButtonProps} href={href} disabled={props.disabled} {...conditionalProps} />}
            </div>
        );
        const buttonLabel = props.label != null && props.label !== ""
          ? (
            <label className="title">{getLocalizedProperty(props, 'label')}</label>
          )
          : null

        input = (
          <React.Fragment>
            {buttonLabel}
            {button}
          </React.Fragment>
        );
        break;
      case GenericInputTypes.CALCULATED_INPUT:
        input = (
          <WagxCalculatedInput {...props} onChange={this.props.onChange} disabled={this.props.disabled} />
        );
        break;
      case GenericInputTypes.TOGGLE:
        const className = ["ToggleContainer"];
        const label = evaluateFunctions.customLabelRender(this.props);
        let booleanVal = false;

        if (value !== "" && value != null) {
          booleanVal = value !== "false" && value !== false && value !== '0' && value !== 0;
        }
        if (this.props.formFeedBack && this.props.formFeedBack !== "") {
          className.push("invalidBox");
        }

        input = (
          <div className={className.join(" ")}>
            {label}
            <Toggle
              title={props.title}
              disabled={props.disabled}
              checked={booleanVal}
              onText={getLocalizedProperty(props, 'onText', "On")}
              offText={getLocalizedProperty(props, 'offText', "Off")}
              onChange={(event, checked) => props.onChange(event, props.field, checked, null)}
            />
          </div>
        );
        break;
      case GenericInputTypes.AUTOCOMPLETE:
        input = (
          <WagxAutocomplete
            {...props}
            disabled={props.disabled}
            value={formatters[props.formatter](value)}
            dependsOnFields={dependsOnFields}
            onRenderLabel={evaluateFunctions.customLabelRender}
          />
        );
        break;
      case GenericInputTypes.LINK:
        const additionalPropsLink = {};

        if (props.title) {
          additionalPropsLink.title = getLocalizedProperty(props, "title", props.title, props.object);
        }

        let disabled = false;
        if (this.props.enableIf) {
          const funcBody = evaluateFunctions.computeFunctionBody(this.props.enableIf);
          // eslint-disable-next-line 
          const func = new Function("object", "profiles", funcBody);
          disabled = !(func(props.object, this.props.appState.profiles));
        }

        input = (
          <React.Fragment>
            {props.label ? <Label className={"GenericInputLabelTitle" + (props.className == null ? "" : " " + evaluateFunctions.parseString(props.className, props.object)) + (props.classNameLabel == null ? "" : " " + evaluateFunctions.parseString(props.classNameLabel, props.object))} >{props.label}</Label> : null}
            <Label className={"GenericInputLabelValue" + (props.className == null ? "" : " " + evaluateFunctions.parseString(props.className, props.object)) + (props.classNameValue == null ? "" : " " + evaluateFunctions.parseString(props.classNameValue, props.object))} {...additionalPropsLink}>
              <WagxLink
                {...props}
                {...additionalPropsLink}
                disabled={disabled}
              />
            </Label>
          </React.Fragment>
        );
        break;
      case GenericInputTypes.SLIDER:
        input = (
          <TooltipHost content={toolTip} id={this.hostId}>
            <Label {...props.labelProps} >{props.label}</Label>
            <Slider
              placeholder={placeholder}
              defaultValue={props.defaultValue == null ? 0 : props.defaultValue}
              min={props.min == null ? "any" : props.min}
              max={props.max == null ? "any" : props.max}
              step={props.step == null ? 1 : props.step}
              showValue={props.showValue}
              disabled={props.disabled}
              value={this.getViewValue()}
              valueFormat={(value) => `${value} ` + props.valueFormat}
              snapToStep
              onChange={(value) => { props.onChange(null, props.field, value); }}
            />
          </TooltipHost>
        );
        break;
      case GenericInputTypes.DATE_TIME_PICKER:
        input = (
          <div className={"ms-Grid-col ms-sm" + props.xs.size + " ms-smPush" + props.xs.offset + " " + props.className}>
            <DateTimePicker
              onChange={this.props.onChange}
              onRenderLabel={(props, defaultRender) => evaluateFunctions.customLabelRender(this.props, defaultRender)}
              {...props} />
          </div>
        );
        break;
      case GenericInputTypes.ICON:
        const additionalProps = {};
        const iconName = evaluateFunctions.parseString(props.iconName, this.props.object);

        if (props.title) {
          additionalProps.title = props.title;
        }
        input = (
          <React.Fragment>
            {props.value != null && props.value !== "" ? <Label className={"GenericInputLabelTitle" + (props.className == null ? "" : " " + props.className)} >{props.value ? " " + evaluateFunctions.parseString(props.value.toString(), props.object) + " " : ""}</Label> : null}

            <Icon iconName={iconName} className={props.classNameIcon == null ? "" : " " + evaluateFunctions.parseString(props.classNameIcon, props.object)} {...additionalProps} />
            {props.label != null ? <Label className={"GenericInputLabelTitle" + (props.className == null ? "" : " " + props.className)} >{props.label}</Label> : null}
          </React.Fragment>
        );
        break;
      case GenericInputTypes.TEXT_BORDER:
        var title = props.title ? evaluateFunctions.parseString(props.title, props.object) : null;
        var textWithBorder = evaluateFunctions.parseString(props.text, props.object)
        input = (
          <React.Fragment>
            {props.label != null ? <Label className={"GenericInputLabelTitle" + (props.className == null ? "" : " " + props.className)} >{props.label}</Label> : null}
            <TextWithBorder {...props} title={title} text={textWithBorder}></TextWithBorder>
          </React.Fragment>)
        break;
      case GenericInputTypes.VIEW:
        var viewId = props.inputViewId

        const viewProps = props.viewMap[viewId];

        input = (
          <Dynamic
            {...this.props}
            viewMap={props.viewMap}
            viewId={viewId}
            view={{ ...viewProps }}
            url={this.props.location.pathname}
          />
        )
        break;
      case GenericInputTypes.DYNAMIC:
        let DynamicInputComponent = null;
        if (this.DynamicInputComponent == null) {
          this.DynamicInputComponent = React.lazy(() => import("../../../" + props.componentPath));
        }
        DynamicInputComponent = this.DynamicInputComponent;
        if (this.props.wrapInDiv === true || this.props.wrapInDiv == null) {
          input = <div className={"ms-Grid-col ms-sm" + props.xs.size + " ms-smPush" + props.xs.offset + " " + props.className}>
            <Suspense fallback={<Spinner label={intl.get("DynamicInput.loading").d("Caricamento in corso...")} />}>
              <DynamicInputComponent {...this.props} value={value} dependsOnFields={dependsOnFields} />
            </Suspense>
          </div>;
        } else {
          input = <Suspense fallback={<Spinner label={intl.get("DynamicInput.loading").d("Caricamento in corso...")} />}>
            <DynamicInputComponent {...this.props} value={value} dependsOnFields={dependsOnFields} />
          </Suspense>
        }

        break;
      case GenericInputTypes.FORM_SUBMIT:
        input = (
          <FormSubmit {...this.props} />
        );
        break;
      case GenericInputTypes.CONTEXT_MENU:
        input = (
          <ContextMenu {...this.props} />
        );
        break;
      case GenericInputTypes.GOOGLE_PLACES_AUTOCOMLETE:
        input = (
          <GooglePlacesAutocomplete {...props} />
        );
        break;
      case GenericInputTypes.ADVANCED_SELECT:
        input = (
          <WagxAdvSelectField
            {...this.props}
            onRenderLabel={evaluateFunctions.customLabelRender.bind(this)}
          />
        );
        break;
      case GenericInputTypes.TINYMCE_TEXTAREA:
        input = (
          <WagxEditor {...this.props} />
        );
        break;
      case GenericInputTypes.RATING:
        input = this.renderRating(value, toolTip);
        break;
      default:
        input = null;
        break;
    }

    if (props.isGroupedInput || input == null) {
      return input;
    } else {
      if (props.avoidContainer) {
        return input;
      } else {
        let classes = [];

        classes.push(generateGridColumnSizeClassName(props, ()=>props.object ));
        classes.push(props.className ? evaluateFunctions.parseString(props.className, props.object) : "");
        classes.push(props.conflictValueInvolved ? "conflict-value-involved" : "");
        return (
          <div className={classes.join(" ")}>
            {input}
          </div>
        );
      }
    }
  }

  renderRating = (value, toolTip) => {
    const props = this.props;
    const label = evaluateFunctions.customLabelRender(props);

    const className = ["rating-container"];
    if (props.formFeedBack && props.formFeedBack !== "") {
      className.push("invalidBox");
    }

    const defaultRating = props.defaultRating != null
      ? props.defaultRating
      : props.defaultvalue
        ? props.defaultValue
        : undefined;

    return (
      <TooltipHost content={toolTip} id={this.hostId}>
        <div className={className.join(" ")}>
          {label}
          <Rating
            id={"inp-" + props.field}
            size={props.useSmallStar === true ? RatingSize.Small : RatingSize.Large}
            max={props.max != null ? props.max : 5}
            disabled={props.disabled}
            readOnly={props.readOnly}
            allowZeroStars={props.allowZeroStars === true}
            defaultRating={defaultRating}
            rating={value}
            onChange={(event, value) => props.onChange(event, props.field, value)}
          />
        </div>
      </TooltipHost>
    );
  }
}

GenericInput.contextType = ViewContext;

const mapStateToProps = state => {
  return {
    appState: state.auth.appState,
    viewMap: state.auth.appState.viewMap,
    page: state.page
  };
}
const mapDispatchToProps = dispatch => {
  return {
    showSearchModal: (search, mainStyle, objectId, loadedObject) => dispatch(actions.showSearchModal({ search: search, mainStyle: mainStyle, objectId: objectId, loadedObject: loadedObject })),
    showIframeModal: (url, title, width, height) => dispatch(actions.showIframeModal({ url: url, title: title, width: width, height: height })),
    setPageState: (state) => dispatch(actions.setPageState({ state: state })),
    showSidePanel: (name) => dispatch(actions.showSidePanel(name)),
    hiddenSearchModal: () => dispatch(actions.hideSearchModal()),
    showLongOperationWaitingModal: () => dispatch(actions.showLongOperationWaitingModal()),
    hideLongOperationWaitingModal: () => dispatch(actions.hideLongOperationWaitingModal())
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenericInput));